@mixin card() {
  border-radius: var(--border-radius);
  background-color: var(--color-white);
  padding: var(--gap);
  margin-bottom: var(--gap);
  border: 1px solid transparent;
  box-shadow: var(--box-shadow);
  min-width: var(--max-col-width);

  &.active {
    background-color: var(--color-accent-bg);
    border-color: var(--color-accent);
  }

  [role='doc-subtitle'] {
    font-size: var(--font-size-tiny);
  }

  > .content {
    flex-grow: 1;
    margin-bottom: var(--gap);
    text-align: center;
  }

  > footer {
  }
}

@mixin h1 {
  font-size: 4.2rem;
  color: var(--color-primary);
  margin: 1rem 0;
}

@mixin h2 {
  font-size: 1.8rem;
  font-weight: var(--font-bold);
  letter-spacing: var(--letter-spacing-medium);
  line-height: 1.5;
  margin: 1.2rem 0;
  text-transform: uppercase;
}

@mixin h3 {
  font-size: 2.1rem;
  font-weight: var(--font-bold);
  line-height: 1.2;
  margin: 0.5rem 0;
  &:first-child {
    margin-top: 0;
  }
}

@mixin label {
  display: block;
  cursor: pointer;
  font-weight: var(--font-bold);
  font-size: var(--font-size-tiny);
  color: var(--color-primary);
  letter-spacing: var(--letter-spacing-large);
  line-height: 1.14;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}
