//error state
.form-item-wrapper:not(:only-child) .form-item .selector {
  &::before {
    border-color: var(--color-error);
  }

  &:not(:focus-within) .selector__dropdown {
    border-color: var(--color-error);
  }
}

.form-item {
  --form-item-padding-h: var(--gap);
  --form-item-padding-v: var(--gap);
}

.selector {
  --animation-speed: 0.3s;
  position: relative;

  // round corners at the bottom of .form-item and on-top of the .country-list dropdown
  &::before {
    content: '';
    z-index: 5;
    position: absolute;
    left: calc(-1 * (var(--form-item-padding-h) + 2px));
    right: calc(-1 * (var(--form-item-padding-h) + 2px));
    height: calc(var(--form-item-padding-v) + 2px);
    bottom: calc(-1 * (var(--form-item-padding-v) + 2px));
    border: 2px solid var(--color-primary);
    background: var(--color-white);
    border-top: none;
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    overflow: hidden;
  }

  &--close {
    &::before {
      animation: delayHide 0s ease var(--animation-speed) forwards;

      @keyframes delayHide {
        to {
          content: none;
        }
      }
    }

    .selector__dropdown {
      max-height: 0;
      border-width: 0;
      margin: 0;
      padding: 0;
      animation: dropdownClose var(--animation-speed) ease forwards;
      overflow-y: hidden;

      @keyframes dropdownClose {
        100% {
          box-shadow: 0px 3px 2px 0px rgb(0 0 0 / 0%);
          visibility: hidden;
        }
      }
    }
  }

  &:not(:focus-within) {
    &::before {
      border-color: var(--color-white);
    }

    .selector__dropdown {
      border-color: var(--color-white);
    }
  }

  &__dropdown {
    position: relative;
    margin-top: .25rem;
    width: var(--dropdown-width);
    max-height: var(--dropdown-height, 230px);
    left: calc(-1 * (var(--form-item-padding-h) + 2px));
    background: var(--color-white);
    padding: 0 2rem;
    border: 2px solid var(--color-primary);
    border-top: none;
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    box-shadow: 0px 3px 2px 0px rgb(0 0 0 / 10%);
    padding-top: var(--form-item-padding-v);
    z-index: 1;

    transform-origin: top;
    transition: max-height var(--animation-speed) ease;

    // scrollbar ----
    overflow-y: hidden;
    animation: delayScrollbar 0s ease var(--animation-speed) forwards;

    @keyframes delayScrollbar {
      to {
        overflow-y: auto; // fallback firefox
        overflow-y: overlay;
      }
    }

    scrollbar-width: 3px;

    &::-webkit-scrollbar {
      width: 9px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      margin-box: 25px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-greyish);
      border-radius: 7px;
      background-clip: content-box;
      border: 2.5px solid transparent;
    }

    // -----

    &__option {
      cursor: pointer;
      font-size: var(--font-size-large);
      display: flex;
      align-items: center;
      padding: 2rem 0 2rem 1rem;
      margin-left: 3rem;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(11, 90, 105, 0.1);
      }

      &[disabled] {
        color: var(--color-greyish);
      }

      &[hidden] {
        display: none;
      }

      &:after,
      &:before {
        content: '';
        position: absolute;
        left: 2rem;
        height: 2.4rem;
        width: 2.4rem;
        border: 1px solid var(--color-greyish);
        border-radius: 50%;
        margin-right: 1rem;
      }

      &.--selected {
        font-weight: var(--font-bold);

        &:after {
          content: '';
          height: 2.4rem;
          width: 2.4rem;
          border: 0.8rem solid var(--color-accent);
          border-radius: 50%;
          margin-right: 1rem;
        }
      }
    }
  }
}