@import 'components/mixins.scss';

.btn,
button {
  display: block;
  cursor: pointer;
  width: var(--max-col-width);
  height: min-content;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  user-select: none;

  &.btn-default {
    font-size: var(--font-size-normal);
    font-weight: 600;
    line-height: 1;
    padding: 1.3rem 1.6em 1.1rem;
    height: 4.6rem;
    letter-spacing: var(--letter-spacing-large);
    text-transform: uppercase;

    &:not(.btn--outline) {
      color: var(--color-white);
      border-radius: 5em;
      box-shadow: var(--box-shadow);
      background: var(--color-accent);
    }

    &.btn--outline {
      color: var(--color-text);
    }

    &.btn--red {
      background-color: #d64747;
    }
  }

  &.btn-scan,
  &.btn-add,
  &.btn-logout {
    @include h2();
    font-size: var(--font-size-small);
    border-radius: var(--border-radius-half);
    border: 2px solid var(--color-secondary);
    color: var(--color-secondary);
    padding: 0.5em;
    width: 100%;

    &.btn-scan {
      padding: 0.5em 3em 0.3em;
      background: url(/assets/icons/qr.svg) left 0.75em center / auto 60%
        no-repeat;
    }
  }

  &.btn--small {
    font-size: 1.2rem;
    padding: 1.1rem 1.6rem 0.9rem;
    height: auto;
  }

  &:active {
    filter: brightness(85%);
  }

  &:disabled:not(&.btn--loading) {
    opacity: 0.25;
  }

  svg#loading-spinner {
    width: 2.2rem;
    height: 2.2rem;
    margin-top: -0.7rem;
    margin-bottom: -0.7rem;
  }
}
