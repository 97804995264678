@import 'the-new-css-reset';
@import 'components/mixins.scss';

@font-face {
  font-family: 'Khmer OS System';
  src: url('/assets/fonts/Khmer-OS-System.ttf.woff') format('woff'),
    url('/assets/fonts/Khmer-OS-System.ttf.svg#Khmer-OS-System') format('svg'),
    url('/assets/fonts/Khmer-OS-System.ttf.eot'),
    url('/assets/fonts/Khmer-OS-System.ttf.eot?#iefix')
      format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

:root {
  /* colors */
  --color-primary: #3dbdc7;
  --color-secondary: rgb(11, 90, 105);
  --color-secondary-50: rgba(11, 90, 105, 0.5);
  --color-secondary-65: rgba(11, 90, 105, 0.65); //minimum accepted contrast
  --color-accent: #61a534;
  --color-accent-bg: rgba(97, 165, 52, 0.1);
  --color-bg: #ecf7fb;
  --color-white: #fff;
  --color-greyish: #c2d6d9;
  --color-greyish-light: #e6eef0;
  --color-error: #e97b7b;
  --color-text: #096574;
  --color-overlay: rgba(47, 117, 131, 0.5);

  --font-thin: 300;
  --font-normal: 400;
  --font-bold: 600;

  --font-size-tiny: 1.2rem;
  --font-size-small: 1.4rem;
  --font-size-normal: 1.6rem;
  --font-size-large: 1.8rem;
  --font-size-xl: 2rem;

  --letter-spacing-medium: 0.2em;

  --letter-spacing-large: 0.2em;

  --max-col-width: min(100%, 35rem);
  --max-container-width: 40rem;

  /* font */
  --font-size: 62.6%; /* 1rem = 62.5% of the browser defined font size (usually 16px which makes 1rem = 10px, but depends on DPI settings, etc. )*/
  --line-height: 1.3;
  --font-color: var(--color-secondary);
  /* native fonts for apple/windows/android */
  --font-family: 'Khmer OS System', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --font-family-header: var(--font-family);

  --opacity-disabled: 0.25;

  --gap: 1rem;
  --gap-half: 0.5rem;
  --gap-double: 2rem;

  --border-radius: 1rem;
  --border-radius-half: 0.5rem;
  --box-shadow: 0 0.3rem 1.2rem 0 rgba(0, 0, 0, 0.05);

  font: normal normal normal var(--font-size) / var(--line-height)
    var(--font-family); /* use this as the basis, 1rem */
}

html {
  height: -webkit-fill-available; /* necessary to deal with the usual safari bugs on ios, from https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ */
}
body {
  &[data-lang='km'] {
    --font-size-tiny: 1.4rem;
    --font-size-small: 1.6rem;
    --font-size-normal: 1.8rem;
    --font-size-large: 2rem;
    --font-size-xl: 2.2rem;

    &,
    p,
    h2,
    h3,
    .form-section label,
    .form-item label,
    .page header [role='doc-subtitle'],
    button {
      letter-spacing: -0.1em !important;
    }
  }

  font-size: var(--font-size-normal);
  color: var(--font-color);
  background-color: var(--color-bg);
  overflow-x: hidden;
}

#root {
  height: 100vh;
}

h1 {
  @include h1();
}

h2 {
  @include h2();
}

h3 {
  @include h3();
}

p {
  font-size: var(--font-size-normal);
  margin: 0.42rem 0;
  line-height: 1.3;
}

a {
  cursor: pointer;
  color: var(--color-text);
}

.hidden {
  display: none;
}

.sticky {
  position: sticky;
  &.bottom {
    bottom: 0;
  }
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 4rem var(--gap-double) 2rem;
  position: relative; /* For carousel absolute positioning */

  > header {
    text-align: center;
  }

  .content {
    width: 100%;
  }

  > footer {
    width: 100%;
    display: grid;
    gap: var(--gap);
  }
}

.overlay .modal.message,
.message {
  @include card();
  padding: var(--gap-double);
  background-color: var(--color-secondary);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);

  header,
  .content {
    color: var(--color-white);
    text-align: left;
  }

  .content {
    font-size: var(--font-size-small);
  }
  button.btn--outline,
  .back-button {
    color: var(--color-white);
  }
}

.select__menu-portal {
  z-index: 10000 !important;
}

.text-button {
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.image-list {
  display: grid;

  grid-auto-columns: 3rem;
  grid-auto-flow: column;
  height: 3rem;
  column-gap: var(--gap-half);
  margin: 0.25em 0;
  > .image {
    position: relative;
    height: 100%;
    aspect-ratio: 1;
    border-radius: var(--border-radius-half);
  }
  .btn-delete {
    cursor: pointer;
    position: absolute;
    right: -0.25em;
    top: -0.25em;
    width: 1em;
    height: 1em;
  }
  img.thumb {
    border-radius: var(--border-radius-half);
    object-fit: cover;
    display: block;
    height: 100%;
    width: 100%;
  }
}

.list {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .all {
      opacity: 0.5;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.action-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  .container {
    width: 100%;
    max-width: 36rem;
  }

  .action {
    position: relative;
    padding-bottom: 100%;
    cursor: pointer;
    .action-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #fff;
      border-radius: var(--border-radius);

      box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1);
      font-weight: bold;
      .icon {
        display: block;
        max-width: 15rem;
        width: 40%;
        margin-bottom: 0.5em;
      }
    }
  }
}

.page,
.modal {
  header {
    width: 100%;
    h1 {
      text-align: center;
      font-family: var(--font-family-header);
      font-weight: 600;
      font-size: 4.2rem;
    }

    [role='doc-subtitle'] {
      text-align: center;
      line-height: 1;
      font-size: var(--font-size-small);
      letter-spacing: var(--letter-spacing-large);
      text-transform: uppercase;
      font-weight: var(--font-bold);
    }

    [role='doc-description'] {
      text-align: center;

      line-height: 1.33;
      margin-top: var(--gap-double);
      font-size: var(--font-size-large);
      color: var(--color-text);
    }
  }
}

.tag {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  font-size: 1rem;
  padding: 0.35em 0.7em;
  background-color: var(--color-accent);
  color: var(--color-white);
  border-radius: 1em;
  font-weight: 800;
  margin: 0.75rem;
  text-transform: uppercase;
}

.bar {
  z-index: 2;
  min-height: 5rem;
  position: fixed;
  width: 100vw;
  text-align: center;
  display: flex;
  line-height: 1;
  align-items: center;
  justify-content: center;
  font-weight: var(--font-bold);
  color: green;
  &.top {
    top: 0;
  }

  &[data-queue='2'] {
    color: red;
  }

  &[data-queue='3'] {
    color: orange;
  }
}

.lvl-label {
  background: #0b5a69;
  border-radius: 0.3rem;
  font-size: var(--font-size-tiny);
  font-weight: 800;
  color: #ffffff;
  letter-spacing: 0;
  padding: 0.5rem;
  width: fit-content;
}
