@import 'components/mixins.scss';

.overlay {
  --modal-color-overlay: rgba(47, 117, 131, 0.5);
  --modal-color-bg: var(--color-bg);

  &[data-modal-type*='floating'] {
    z-index: 101;
  }

  &[data-modal-type*='bottom'] {
    z-index: 102;
  }

  .modal {
    width: 100%;
    overflow: auto;
    padding: 4rem var(--gap-double); /* make the padding same as normal page */

    &.bottom-sheet {
      border-radius: 2rem 2rem 0 0;
      top: 3%;
    }

    &.floating {
      border-radius: var(--border-radius);
      max-width: min(90vw, 40rem);
      padding: var(--gap-double);
      overflow: visible;
      margin: 0;

      &.bottom {
        align-self: flex-end;
        min-height: min(90vh, 40rem);
        flex-basis: 40rem;
        overflow: visible;
        height: auto;
        min-height: 0;
      }

      // the buttons row at the bottom
      .bottom {
        display: flex;
        flex-direction: row;
        padding-top: var(--gap-double);
        margin-top: var(--gap-double);
        border-top: 1px solid rgba(11, 90, 105, 0.1);
        justify-content: space-between;

        button {
          width: auto;
          margin: 0;
        }
      }
    }

    &:not(.floating) {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: min-content auto minmax(min-content, 6rem);
      grid-template-areas: 'header' 'main' 'footer';
      gap: var(--gap-double);
      padding-top: calc(var(--gap-double) * 2);
      overflow: auto;

      > * {
        width: 100%;
      }

      .bottom {
        position: sticky;
        bottom: 0;
        grid-area: footer;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .modal-mini {
      width: 100%;

      .bottom {
        padding-top: var(--gap-double);
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button,
        .btn {
          margin: 0;
          width: auto;
        }
      }
    }

    header {
      grid-area: header;
      margin-bottom: var(--gap);
    }

    footer {
      grid-area: footer;
      display: grid;
      gap: var(--gap);
    }

    .content {
      grid-area: main;
    }
  }
}
