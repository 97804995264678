.back-button {
  cursor: pointer;
  padding: 0.5em;
  font-size: --font-size-large;
  line-height: 1em;
  color: var(--color-secondary-50);

  &.image-button {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    &:not(&.back-button--outline) {
      width: 2.3em;
      height: 2.3em;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0 5px 14px 0 rgb(0 0 0 / 10%);
    }

    svg {
      margin-right: 3px;
    }
  }

  &__placeholder {
    width: 2.3em;
    height: 2.3em;
    margin-bottom: var(--gap);
  }
}
