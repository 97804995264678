.nav-main {
  background-color: #fff;
  padding-bottom: var(--gap);
  padding-top: var(--gap-half);

  .item > a {
    display: flex;
    justify-content: center;
    &:not(.active) {
      svg {
        display: block;
        opacity: 0.25;
      }
    }
  }
}
