@import 'components/mixins';

#app {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: 'header' 'main' 'footer';
  grid-template-rows: max-content auto max-content;
  height: 100vh;
  height: -webkit-fill-available; /* necessary to deal with the usual safari bugs on ios, from https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ */

  > :only-child {
    grid-area: main; //if there is only one element, then treat it as main area
  }

  > header {
    grid-area: header;
  }
  > main {
    grid-area: main;
    overflow: auto;

    .buttons {
      margin: var(--gap-double) 0;
    }
  }

  > .nav-main,
  footer {
    grid-area: footer;
  }

  .card {
    @include card();
    &.image-left {
      display: flex;
      flex-direction: row;
      .image {
        display: block;
        align-self: flex-start;
        min-width: 8rem;
        height: 8rem;
        border-radius: var(--border-radius);
        margin-right: 1.2rem;
      }
      .content {
        font-size: var(--font-size-small);
        margin-bottom: 0;
        text-align: left;
        h3 {
          font-weight: var(--font-bold);
          margin-top: 0;
        }
        button {
          width: auto;
          margin-top: var(--gap);
          margin-left: 0;
        }
      }
    }
  }
}
