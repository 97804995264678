@import 'mixins';

:root {
  --checkbox-background: #fff;
  --checkbox-border: var(--color-greyish);
  --checkbox-checked: var(--color-accent);
}

::placeholder,
.form-item .placeholder {
  color: var(--color-greyish);
}

.form-section {
  margin-bottom: var(--gap-double);
}

.form-multi-item,
.form-item {
  margin-bottom: var(--gap-half);

  header {
    display: flex;
    justify-content: space-between;

    h2 {
      margin: 0;
    }
  }
}

.form-item-wrapper {

  //identify error occupance when wrapper of .form-item doesn't has another element, ie error div
  &:not(:only-child),
  &:not(:only-child):focus-within {
    .form-item {
      border-color: var(--color-error);
      color: var(--color-text);

      label {
        color: var(--color-error);
      }

      :-webkit-autofill {
        -webkit-text-fill-color: var(--color-text);
      }
    }
  }

  .help {
    margin-left: var(--gap);
    font-size: var(--font-size-small);
  }
}

.form-item {
  @include card();
  padding: var(--gap);
  text-align: left;
  border-width: 2px;

  &:not(.active):focus-within {
    border-color: var(--color-primary);
  }

  .upload-image {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  label {
    @include label();
  }

  input {
    width: 100%;
    font-size: var(--font-size-large);
    line-height: 1;
    margin: -1rem 0;
  }
}

.error-msg {
  text-align: left;
  color: var(--color-error);
  margin-left: var(--gap);
  margin-bottom: var(--gap);
}

.select-label {
  cursor: pointer;
  width: 100%;
  background: url('/assets/select-handle.svg') center right/contain no-repeat;
}

select {
  cursor: pointer;
  width: 100%;
  background: url('/assets/select-handle.svg') center right/contain no-repeat;

  &:not(:valid) {
    color: var(--color-greyish);
  }
}

.radios,
.checkboxes {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  .radio,
  .checkbox {
    cursor: pointer;
  }
}

@mixin vh() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

input[type='checkbox'],
input[type='radio'] {
  @include vh();

  +label {
    display: block;
    cursor: pointer;
    font-size: 2rem;
    color: var(--font-color);
    letter-spacing: 0;
    line-height: 1.25;
    position: relative;
    padding-left: 1.75em;
    user-select: none;
    text-transform: none;
    margin: 0;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1.25em;
      height: 1.25em;
      border: 2px solid var(--checkbox-border);
      border-radius: 0.25em;
      background: var(--checkbox-background);
      text-align: center;
      transition: background-color 200ms ease-out;
    }

    &:after {
      content: '';
      position: absolute;
      transform: scale(0);
      transition: transform 200ms ease-out;
    }
  }
}

input[type='checkbox'] {
  +label {
    &:after {
      background-color: transparent;
      content: '';
      display: block;
      position: absolute;
      left: 0.65em;
      top: 0.25em;
      // margin-left: 0;
      // margin-top: 0;
      width: 0.5em;
      height: 1em;
      opacity: 0.2;
      border-bottom: 3px solid var(--checkbox-checked);
      border-right: 3px solid var(--checkbox-checked);
      transform: rotate(45deg);
      transition: border-color 0.3s ease;
    }
  }

  &:checked {
    +label {
      &:before {
        content: '';
      }

      &:after {
        content: '';
        opacity: 1;
      }
    }
  }
}

input[type='radio'] {
  +label {

    &:before,
    &:after {
      border-radius: 50%;
    }

    &:after {
      left: 0.25em;
      top: 0.25em;
      width: 0.75em;
      height: 0.75em;
    }
  }

  &:checked {
    +label {
      &.--checked-icon:after {
        --checkbox-checked: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><circle fill="%2361A534" cx="12" cy="12" r="12"/><path fill="none" d="m7 12.956522 2.60512 2.616446c.233805.234822.613703.235646.848526.00184a.602653.602653 0 0 0 .00184-.00184L17 9h0" stroke="%23FFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        width: 1.25em;
        height: 1.25em;
        top: 0;
        left: 0;
      }

      &:before {
        animation: borderscale 300ms ease-in;
      }

      &:after {
        background: var(--checkbox-checked);
        transform: scale(1);
      }
    }
  }
}

.radio-group {
  &:not(.--withImages) .form-item {
    padding: var(--gap-double);
  }

  &.--withImages {
    .radios {
      display: grid;
      gap: var(--gap);
      grid-template-columns: repeat(auto-fit, minmax(150px, auto));
      justify-content: center;
    }

    .form-item {
      max-width: 250px;
      height: 100%;
      border-width: 2px;
      margin: 0;
      padding: 22% 0 2rem;

      /* use '%' to lock the aspect-ratio */
      &.active {
        background-color: var(--color-white);
        opacity: 1;
      }
    }

    input[type='radio'] {
      +label {
        padding: 0 0 1.75em 0;
        text-align: center;
        font-weight: var(--font-bold);
        font-size: var(--font-size-large);

        &:before {
          border-radius: 50%;
          top: unset;
          bottom: 0;
          left: calc(50% - 1.25em / 2);
        }

        &:after {
          border-radius: 50%;
          top: unset;
          bottom: 0.25em;
          left: calc(50% - 0.75em / 2);
        }

        &.--checked-icon:after {
          bottom: 0;
          left: calc(50% - 1.25em / 2);
        }

        img {
          width: 100%;
        }
      }
    }
  }
}

section.animated {
  transition: all 0.75s;
}

section.animated.preEnter,
section.animated.exiting {
  opacity: 0;
  transform: scale(0.5);
}

section.animated.exited {
  display: none;
}

/* Change autocomplete styles in WebKit */
// src: https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers

*:-webkit-autofill,
*:-webkit-autofill:hover,
*:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--color-accent);
  -webkit-box-shadow: 0 0 0px 1000px var(--color-white) inset;
}

.approve-transaction {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--color-white);

  animation: upAnimation ease 0.25s;

  @keyframes upAnimation {
    from {
      transform: translate(0, 120%);
    }
  }

  &::before {
    content: '';
    position: absolute;
    background: white;
    top: 0;
    left: calc(-1 * var(--gap-double));
    right: calc(-1 * var(--gap-double));
    height: calc(100% + 4rem);
    z-index: -2;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: var(--gap-double) 0;

    .form-item:last-child {
      align-items: flex-end;
    }
  }

  .form-item {
    display: flex;
    flex-direction: column;
    box-shadow: none;
    width: fit-content;
    min-width: fit-content;
    border: none;
    padding: 0;

    &.approve-transaction__total-price {
      align-items: center;
      width: 100%;
      min-width: 100%;
      font-size: 3.2rem;
      margin: var(--gap) 0;
    }
  }

  .approve-transaction__line {
    width: 1px;
    background: var(--color-secondary);
    opacity: 0.1;
  }
}