@import 'components/mixins.scss';

form .form-item-code {
  all: unset;
  --code-length: 5; //this override in component inline style

  label {
    --cell-width: calc(100% / var(--code-length) - var(--gap));

    display: grid;
    grid-template-columns: repeat(auto-fit, min(6rem, var(--cell-width)));
    gap: var(--gap);
    place-items: center;
    justify-content: center;

    div {
      @include card();
      min-width: 100%;
      width: 100%;
      height: 7rem;
      font-size: 4.2rem;
      font-weight: 300;
      line-height: 1;
      display: grid;
      place-items: center;
    }
  }

  input:focus + label .--active {
    border: 2px solid var(--color-primary);
  }

  input {
    height: 0;
    position: absolute;
  }

  + .error-msg {
    margin-left: 0;
  }
}
