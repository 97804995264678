.container,
.container-full {
  &.container-full {
    width: 100%;
  }

  &.container {
    margin: auto;
    width: min(100%, var(--max-container-width));
  }
}
.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .item {
    width: clamp(20rem, 35rem, 100%);
    padding: var(--gap-half);
    display: flex;
    flex-direction: column;
    align-items: center;

    &[disabled] {
      pointer-events: none;
      opacity: var(--opacity-disabled);
    }

    header {
      justify-content: space-between;
    }

    > * {
      width: 100%;
    }

    > h2 {
      align-self: flex-start;
      margin: 0;
    }
  }
}
