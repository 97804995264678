.react-tel-input {
  // round corners at the bottom of .form-item and on-top of the .country-list dropdown
  &:focus-within::before {
    content: '';
    z-index: 5;
    position: absolute;
    left: calc(-1 * (var(--gap) + 2px));
    right: calc(-1 * (var(--gap) + 2px));
    height: calc(var(--gap) + 2px);
    bottom: calc(-1 * (var(--gap) + 2px));
    border: 2px solid var(--color-primary);
    background: var(--color-white);
    border-top: none;
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    overflow: hidden;
  }

  .form-control {
    border: none;
    font-size: var(--font-size-large);
    line-height: 1.25;
    height: 2.25rem;
    padding-left: 4.7rem;
  }

  .flag-dropdown {
    border: none;
    background: none !important;
    z-index: 3 !important;

    .selected-flag {
      border-radius: 5px;
      padding-left: 5px;
      background: none !important;

      &:focus:not(&:active) .flag {
        outline: 2px solid var(--color-primary);
      }

      &.open .flag .arrow {
        transform: scale(0.8) translate(0, 0);
      }
    }

    .flag {
      // Temp fix for flag cropping, which effect applying border-radius
      // PR is done on Github for this: https://github.com/bl00mber/react-phone-input-2/pull/530
      width: 22px;
      height: 16px;
      margin-top: -8px;
      background-image: url(/assets/phone-selector-flags.png);
      // End temp fix -------

      border-radius: 2px;

      .arrow {
        transition: all 0.1s ease;
        border: none;
        width: 11px;
        height: 7px;
        background: url(/assets/icons/select-arrow-grayish.svg);
        transform: scale(0.8) translate(0, -3px);
        left: 2.5rem;
      }
    }
  }

  .country-list {
    width: var(--dropdown-width);
    margin: 0;
    left: calc(-1 * (var(--gap) + 2px));
    border: 2px solid var(--color-primary);
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    border-top: none;
    box-shadow: 0px 3px 2px 0px rgb(0 0 0 / 10%);
    padding-top: var(--gap);
    overflow-x: hidden;
    overflow-y: auto;
    transform-origin: top;
    animation: dropdown 0.2s ease;

    @keyframes dropdown {
      from {
        transform: scaleY(0);
      }
    }

    li.country {
      padding: calc(1.5 * var(--gap));
      font-size: var(--font-size-large);
      display: flex;
      align-items: baseline;

      &:first-child {
        // border-top: 2px solid var(--color-primary);
        padding-top: calc(1.5 * var(--gap));
      }

      &:not(:last-child) {
        border-bottom: 1px solid #0b5a691a;
      }

      &.highlight {
        background: #0b5a690d;
      }

      .country-name {
        margin-left: 0.5rem;
        margin-top: 0.3rem;
      }

      .flag {
        position: relative;
        left: 0;
        top: 1px;
      }
    }
  }
}
