.language-selector {
  --language-selector-position: right;
  --language-selector-width: 5.5rem;
  --language-selector-dropdown-width: 25rem;

  position: relative;
  height: 3.4rem;
  width: var(--language-selector-width);
  border: 0.1rem solid #daecf6;
  border-radius: 0.5rem;
  padding: 0.4rem;
  display: flex;
  align-items: center;

  &__globe-icon {
    height: 100%;
    display: block;
  }

  select {
    all: unset;
    position: absolute;
    cursor: pointer;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
  }

  &--open {
    border: 0.1rem solid var(--color-primary);
    background: #daecf6;
  }

  &__arrow-icon {
    margin: auto;
    transition: all 0.15s ease;
  }

  &--open &__arrow-icon {
    transform: rotate(180deg);
  }

  &--left &__dropdown {
    left: 0;
  }

  &--right &__dropdown {
    right: 0;
  }

  &--center &__dropdown {
    left: calc(
      var(--language-selector-width) / 2 -
        var(--language-selector-dropdown-width) / 2
    );
  }

  &__dropdown {
    position: absolute;
    top: calc(100% + 0.7rem);
    background: #fff;
    padding: 0 2rem;
    width: var(--language-selector-dropdown-width);
    border-radius: 1rem;
    border: 1px solid var(--color-primary);
    transition: all 0.15s ease;
    transform-origin: top var(--language-selector-position);
    transform: scale(0);
    z-index: 2;

    &__option {
      cursor: pointer;
      font-size: var(--font-size-large);
      display: flex;
      align-items: center;
      padding: 2rem 0;
      margin-left: 4rem;

      &:not(:last-child) {
        border-bottom: 1px solid var(--color-accent-bg);
      }

      &:after,
      &:before {
        content: '';
        position: absolute;
        left: 2rem;
        height: 2.4rem;
        width: 2.4rem;
        border: 1px solid var(--color-greyish);
        border-radius: 50%;
        margin-right: 1rem;
      }

      &.--selected {
        font-weight: var(--font-bold);

        &:after {
          content: '';
          height: 2.4rem;
          width: 2.4rem;
          border: 0.8rem solid var(--color-accent);
          border-radius: 50%;
          margin-right: 1rem;
        }
      }
    }
  }

  &--open &__dropdown {
    transform: scale(1);
  }
}
